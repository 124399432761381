import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import "animate.css/animate.min.css";
import notfound from "../images/notfound.png";
import { homepage, not_found_text} from '../components/strings'
import AOS from 'aos';
class NotFoundPage extends React.Component{
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
    render(){
        return(
    
  <Layout>
    <div data-aos='fade-zoom-in'>
      
        <section id="p_notFound" className="hero is-fullheight pages">
    <div className="hero-head"></div>
        <div className="hero-body is-padding-0">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
           <div className="image-box"> <img src={notfound} alt="Well Business Promotion Logo" /></div>
           <div className="title-box"><h2 className="title">{not_found_text} <Link to="/">{homepage}</Link>.</h2></div>
            <div data-aos='fade-zoom-in' className="container"> 
             
               
            </div>
        </div>
    <div className="hero-foot">
   
    </div>
</section>
    </div>
</Layout>
)
        }
    }

export default NotFoundPage
